<template>
  <content-page :page-slug="'Admin'" class="admin-tools">
    <form @submit.prevent="login">
      <label for="username"
        >Username: <input v-model="username" type="text" />
      </label>
      <label for="password"
        >Password: <input v-model="password" type="password" />
      </label>
      <button type="submit">Login</button>
    </form>
    <a :href="wpUrl" class="admin-tools__button"> Wordpress </a>
  </content-page>
</template>

<script>
import ContentPage from "~/components/ContentPage/ContentPage";
import { get } from "lodash";

export default {
  components: {
    ContentPage,
  },
  data() {
    return {
      username: null,
      password: null,
    };
  },

  computed: {
    wpUrl() {
      const wp = process.env.WP_URL;
      if (wp) {
        return wp;
      }
      return false;
    },
  },

  methods: {
    login() {
      this.$axios
        .post("/api/auth/login", {
          username: this.username,
          password: this.password,
        })
        .then((res) => {
          console.log("logged in");
          this.$store.commit("user/setUser", res.data);
          this.$cookies.set("token", res.data.token);
          // this.username = "";
          // this.password = "";
          window.location = "/admin/tools";
        })
        .catch((error) => console.log("login failed"));
    },

    logout() {
      this.$axios.post("/api/auth/logout", {
        username: this.$store.state.user.current.username,
      });
      this.$store.commit("user/setUser", null);
    },

    isLoggedIn() {
      if (
        this.user.current &&
        this.user.current.username &&
        this.user.current.token
      ) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss">
.admin-tools {
  &__button {
    @include button;
    &:hover {
      background-color: $darkgreen;
      color: white;
    }
  }
}
</style>
